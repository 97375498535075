.main-search-select {
  width: 296px;
  height: 64px;
  border-radius: 30px;
  box-shadow: 0 25px 60px 0 rgb(31 32 33 / 11%);
  border: solid 2px rgba(31, 32, 33, 0.1);

  @media (max-width: 999px) {
    width: 242px;
  }
  @media (max-width: 600px) {
  }
  &:hover {
    border: solid 2px rgba(31, 32, 33, 0.25);
  }

  .List,
  .nav-merchant-options {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &:-ms-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &::placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
}

.main-search-select.main-search-select-open {
  width: 566px;
  z-index: 5;
  border: none;

  @media (max-width: 999px) {
    width: 500px;
    grid-template-rows: 64px auto;
    box-shadow: 0 5px 20px 0 rgb(38 38 38 / 5%);
    background-color: #ffffff;
    height: auto;
  }
  @media (max-width: 600px) {
    width: 100%;
  }

  .main-default-orange-button {
    width: 87px !important;
    height: 44px !important;
    @media (max-width: 600px) {
      width: 44px !important;
      height: 44px !important;
    }
  }
}
